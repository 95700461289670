import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function EyeClosed({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle stroke={color} strokeRound d="M2.78027 21L21.5303 3" />
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M9 18.3013C9.97098 18.6044 10.9828 18.7562 12 18.7513C16.1 18.8213 20.26 15.9413 22.82 13.1113C23.0962 12.8076 23.2492 12.4119 23.2492 12.0013C23.2492 11.5908 23.0962 11.1951 22.82 10.8913C21.8943 9.86822 20.8655 8.94332 19.75 8.13135"
      />
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M17.0902 6.52128C15.5165 5.70486 13.7729 5.26983 12.0002 5.25128C8.00016 5.18128 3.80016 8.00128 1.18016 10.8913C0.903999 11.195 0.750977 11.5908 0.750977 12.0013C0.750977 12.4118 0.903999 12.8075 1.18016 13.1113C2.60004 14.6986 4.26434 16.0489 6.11016 17.1113"
      />
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M9.00025 14.3212C8.45777 13.5686 8.20529 12.6455 8.28924 11.7216C8.3732 10.7976 8.78795 9.93511 9.4572 9.29263C10.1264 8.65015 11.0052 8.27094 11.9318 8.22475C12.8583 8.17856 13.7704 8.46849 14.5002 9.04123"
      />
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M15.75 12.0012C15.75 12.4937 15.653 12.9813 15.4645 13.4363C15.2761 13.8913 14.9999 14.3047 14.6517 14.6529C14.3034 15.0011 13.89 15.2773 13.4351 15.4658C12.9801 15.6542 12.4925 15.7512 12 15.7512"
      />
    </BaseSvgStyle>
  );
}

export default EyeClosed;
